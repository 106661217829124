import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useRouter } from "next/router";
import styles from "../styles/Home.module.css";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import CallIcon from "@mui/icons-material/Call";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import axios from "axios";

const CAllButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  border: "1px solid var(--primary-color)",
  fontWeight: "600",
  width: "250px",
  height: "45px",
  fontSize: "15px",
  backgroundColor: "var(--primary-color)",
  borderRadius: "20px",
  marginTop: "3%",
  "&:hover": {
    border: "1px solid var(--dark-color)",
    backgroundColor: "var(--dark-color)",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "15%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CallToActionTwo() {
  const [open, setOpen] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([
    "access_token",
    "login",
    "callToActionUrlTwo",
    "backToUrlTwo",
  ]);
  const Router = useRouter();
  const [url, setUrl] = React.useState("");

  let alertBox;
  useEffect(() => {
    setUrl(window.location.pathname);
    if (cookies.backToUrlTwo) {
      setOpen(true);
      alertBox = setInterval(() => {
        setOpen(false);
        clearInterval(alertBox);
      }, 8000);
      setCookie("backToUrlTwo", false, { maxAge: 1800 });
      removeCookie("backToUrlTwo", "callToActionUrlTwo");
    }
  }, []);

  const callAction = () => {
    setCookie("callToActionUrlTwo", url, { maxAge: 1800 });
    if (cookies.access_token) {
      setOpen(true);
      alertBox = setInterval(() => {
        setOpen(false);
        clearInterval(alertBox);
      }, 8000);
      axios.post(
        "https://abodefinder.com.au/api/new-lead/",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.access_token}`,
          },
        }
      );
    } else {
      Router.push("/register/");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <section className={styles.callToAction}>
        <Card
          variant="outlined"
          className={styles.callToActionCard}
          sx={{ overflow: "inherit" }}
        >
          <div className={styles.callIcon}>
            <CallIcon sx={{ color: "var(--dark-color)", fontSize: "30px" }} />
          </div>
          <p>
            Book a session with our expert property consultants today and take
            the first step towards securing your financial future.
          </p>
          <CAllButton onClick={callAction}>Book Now</CAllButton>
        </Card>
      </section>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            width: "600px",
            textAlign: "center",
            p: 4,
            [theme.breakpoints.down("sm")]: { width: "280px" },
          })}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "20px", fontWeight: "600" }}
            gutterBottom
          >
            One of our partner consultants contacts you to schedule a meeting.
          </Typography>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
