import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import MainBlogBox from "./mainBlogBox";
import { useEffect, useState } from "react";

const MainBlogsSection = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetch("https://abodefinder.com.au/blog/api/last_article?format=json", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setArticles(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <Box  sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "40px",
    }}>
      <Typography
                variant="h3"
                sx={{
                  fontSize: "30px",
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Our Latest Insights
      </Typography>

    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "40px",
      }}
    >
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          textAlign: "center",
        }}
      >
        {articles?.map((article) => (
          <MainBlogBox
            image={article.image}
            title={article.title}
            subtitle={article.subtitle}
            url={`https://abodefinder.com.au/blog/${article.slug}/`}
          />
        ))}
      </Box>
    </Box>
    </Box>
  );
};

export default MainBlogsSection;
