import React, { useEffect } from "react";
import Link from "next/link";
import AOS from "aos";
import "aos/dist/aos.css";
import Head from "next/head";
import Image from "next/image";
import styles from "../styles/Home.module.css";
import Lottie from "react-lottie-player";
import equityJson from "../json/equity.json";
import repaymentJson from "../json/repayment.json";
import Answer from "../images/undraw_Questions_re_1fy7.png";
import strategy from "../images/undraw_Business_decisions_re_84ag.png";
import Invest from "../images/undraw_Invest_re_8jl5.png";
import Investment from "../images/icons8-stocks-growth-80.png";
import Analysis from "../images/icons8-financial-growth-analysis-64.png";
import Interface from "../images/icons8-checked-user-male-96.png";
import Support from "../images/icons8-online-support-90.png";
import Property from "../images/icons8-search-house-online-99.png";
import House from "../images/undraw_House_searching_re_stk8.png";
import Footer from "../component/footer";
import CallToAction from "../component/callToAction";
import CallToActionTwo from "../component/callToActionTwo";
import MainBlogsSection from "../component/mainBlogsSection";

export default function Home() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Head>
        <title>Discover Your Dream Investment with AbodeFinder's AI</title>
        <link rel="canonical" href="https://abodefinder.com.au/"></link>
        <meta
          property="og:title"
          content="Discover Your Dream Investment with AbodeFinder's AI"
        />
        <meta
          property="og:description"
          content="Find your ideal investment property with AbodeFinder's AI platform. Get personalized recommendations for suburbs and properties based on your preferences."
        />
        <meta property="og:image" content="../images/1.png" />
        <meta property="og:url" content="https://abodefinder.com.au/" />
        <meta
          name="description"
          content="Find your ideal investment property with AbodeFinder's AI platform. Get personalized recommendations for suburbs and properties based on your preferences."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" type="image/png" sizes="32x32" href="https://abodefinder.com.au/blog-static/img/favicon-32.ico"/>
        <link rel="preload" as="image" href="../images/house-1477041.webp" />
      </Head>
      <main className={styles.firstMain}>
        <h1 className={styles.mainp}>
          Unlocking Your Property Investment Potential
        </h1>
        <h2 className={styles.mainp1}>
          Discover Your Dream Home with AbodeFinder
        </h2>
        <div>
          <div className={styles.buttons}>
            <Link className="btn-ab" href="/suburb-finder/">
              Suburb Finder
            </Link>
            <Link className="btn-ab" href="/buying-chance/">
              Buying Chance
            </Link>
          </div>
        </div>
      </main>
      <section className={styles.abodeTextBox}>
        <div className={styles.abodeTextCard} data-aos="zoom-in">
          <p>
            AbodeFinder is the ultimate property investment platform that helps
            you find your dream home and make informed decisions about your
            investment. Discover your ideal suburb and investment opportunities
            based on your budget, preferred state, public transport needs, and
            school type with our user-friendly and intuitive platform. Our
            suburb finder and buying chance calculator offer clear insights into
            your investment options, making it easy for you to make informed
            decisions. Trust AbodeFinder as your ultimate partner in your
            property investment journey.
          </p>
          <Image className={styles.abodeImgMain} src={House} alt="House" />
        </div>
      </section>

      <CallToAction />
      <section className={styles.suburb}>
        <article className={styles.textsuburb}>
          <h3>How is work?</h3>
          <p>
            AbodeFinder is a powerful property investment platform that helps
            you find your dream home and make informed decisions about your
            investment. Here's how it works
          </p>
        </article>
        <article className={styles.animSuburb}>
          <div
            className={styles.workRowOne}
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
          >
            <div className={styles.textWorkOut}>
              <div className={styles.textWorkInside}>
                <div className={styles.circleNumber}>1</div>
                <h4>Answer a few questions</h4>
                <p>
                  To get started, simply answer a few questions about your
                  investment preferences, including your budget, preferred
                  state, public transport needs, and school type. Based on your
                  answers, our platform will provide you with a list of suburbs
                  where you have a chance to buy the property of your dreams.
                </p>
              </div>
            </div>
            <Image className={styles.workImgOne} src={Answer} alt="Answer" />
          </div>

          <div
            className={styles.workRowTwo}
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
          >
            <Image
              className={styles.workImgTwo}
              src={strategy}
              alt="strategy"
            />
            <div className={styles.textWorkOut}>
              <div className={styles.textWorkInside}>
                <div className={styles.circleNumber}>2</div>
                <h4>Choose a strategy</h4>
                <p>
                  AbodeFinder offers two product options to help you find the
                  perfect property investment: our suburb finder and buying
                  chance calculator. With our suburb finder, you can choose
                  between a capital growth or cash flow strategy, and we’ll take
                  care of the rest. Our buying chance calculator helps you
                  calculate your chance of buying a 4-bedroom house or a
                  2-bedroom apartment in a specific suburb.
                </p>
              </div>
            </div>
          </div>

          <div
            className={styles.workRowOne}
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
          >
            <div className={styles.textWorkOut}>
              <div className={styles.textWorkInside}>
                <div className={styles.circleNumber}>3</div>
                <h4>Invest with confidence</h4>
                <p>
                  AbodeFinder makes it easy to invest with confidence. With
                  clear insights into your investment options and a
                  user-friendly platform, you can make informed decisions about
                  your property investment. Trust AbodeFinder as your ultimate
                  partner in your property investment journey.
                </p>
              </div>
            </div>
            <Image className={styles.workImgOne} src={Invest} alt="Invest" />
          </div>
        </article>
      </section>
      <section className={styles.wealth}>
        <article className={styles.wealthText}>
          <h3>Why should clients use AbodeFinder as an investment platform</h3>
          <p>
            There are several reasons why clients should use AbodeFinder as
            their preferred property investment platform
          </p>
        </article>
        <article className={styles.wealthFieldsRow}>
          <div className={styles.wealthFieldsBox} data-aos="fade-up">
            <Image
              className={styles.wealthFieldsBoxImg}
              src={Investment}
              alt="Investment"
            />
            <h5>Personalised Investment Prediction</h5>
            <p>
              AbodeFinder is designed to provide personalised investment
              predictions based on your specific needs and preferences. Our
              platform considers factors such as your budget, preferred
              location, and investment strategy to suggest properties that best
              align with your goals.
            </p>
          </div>

          <div className={styles.wealthFieldsBox} data-aos="fade-up">
            <Image
              className={styles.wealthFieldsBoxImg}
              src={Analysis}
              alt="Analysis"
            />
            <h5>Comprehensive Data Analysis</h5>
            <p>
              Our platform uses advanced data analysis tools to provide you with
              a comprehensive report on the property market, including median
              house prices, rental yields, and other key metrics. This allows
              you to make informed investment decisions and avoid costly
              mistakes.
            </p>
          </div>

          <div className={styles.wealthFieldsBox} data-aos="fade-up">
            <Image
              className={styles.wealthFieldsBoxImg}
              src={Interface}
              alt="Interface"
            />
            <h5>User-Friendly Interface </h5>
            <p>
              AbodeFinder has a user-friendly interface that makes it easy to
              find properties that match your investment goals. Our platform is
              intuitive and easy to navigate, allowing you to filter properties
              by key features such as location, property type, and price range.
            </p>
          </div>
        </article>
        <article className={styles.wealthFieldsRow}>
          <div className={styles.wealthFieldsBox} data-aos="fade-up">
            <Image
              className={styles.wealthFieldsBoxImg}
              src={Property}
              alt="Property"
            />
            <h5>Efficient Property Search</h5>
            <p>
              {" "}
              AbodeFinder saves you time by streamlining the property search
              process. Rather than manually browsing through hundreds of
              listings, our platform provides you with a curated list of
              properties that match your criteria, making it easier to find the
              right investment opportunity.
            </p>
          </div>

          <div className={styles.wealthFieldsBox} data-aos="fade-up">
            <Image
              className={styles.wealthFieldsBoxImg}
              src={Support}
              alt="Support"
            />
            <h5>Expert Support</h5>
            <p>
              AbodeFinder offers expert support to help guide you through the
              property investment process. Our team of experienced professionals
              is available to answer any questions you may have and provide
              personalised advice to help you make informed investment
              decisions.
            </p>
          </div>
        </article>
      </section>
      <CallToActionTwo />
      <section className={styles.comparisonType1}>
        <article className={styles.textSuburbCo} data-aos="fade-down">
          <p>
            Find out how much equity you may be able to access from your home
            loan.
          </p>

          <Link className={styles.btnSuburb} href="/equity_calculator/">
            Equity calculator
          </Link>
        </article>
        <article className={styles.animSuburb}>
          <Lottie
            loop
            animationData={equityJson}
            play
            background="transparent"
            speed="1"
            className={styles.lottieAniCo}
          />
        </article>
      </section>
      <section className={styles.calculator}>
        <article className={styles.textCalculator} data-aos="fade-up">
          <p>
            Find out what your home loan repayments might look like and how they
            are calculated.
          </p>
          <Link className={styles.btnCalculator} href="/repayment_calculator/">
            Repayment calculator
          </Link>
        </article>
        <article className={styles.animCalculator}>
          <Lottie
            loop
            animationData={repaymentJson}
            play
            background="transparent"
            speed="1"
            className={styles.lottieCal}
          />
        </article>
      </section>

      <MainBlogsSection />

      <Footer />
    </>
  );
}
