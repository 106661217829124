import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Link from "@mui/material/Link";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const MainBlogBox = ({ image, title, url, subtitle }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Box
        sx={(theme) => ({
          width: "31%",
          [theme.breakpoints.down("md")]: {
            width: "100%",
            marginBottom: "2rem",
          },
          height: "420px",
          overflow: "hidden",
          border: "1px solid #dee2e6",
          borderRadius: "0.375rem",
        })}
        data-aos="zoom-in"
      >
        <Link href={url} sx={{ textDecoration: "none" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <Box
              sx={{
                width: "100%",
                backgroundImage: "url(" + `${image}` + ")",
                height: "25rem",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                zIndex: "10",
              }}
            ></Box>

            <Box
              sx={{
                width: "100%",
                background: "white",
                padding: "1rem 1rem",
                height: "41%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5"
                className="blog-box-title"
                sx={{
                  fontSize: "18px",
                  color: "black",
                  fontWeight: "bold",
                  textAlign: "start",
                  "&:hover": {
                    color: "var(--primary-color)",
                  },
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: "#212529bf                ",
                  textAlign: "start",
                }}
                className="blog-box-text"
              >
                {subtitle}
              </Typography>
            </Box>
          </Box>
        </Link>
      </Box>
    </>
  );
};

export default MainBlogBox;
